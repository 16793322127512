<template>
		<div>
			<h2>{{ $t("check.configuration_remise") }}</h2>

		    <form class="mb-4">
	            <div class="form-group">
	                <label>{{ $t('check.checkdeposit_ref') }}</label>
	                <input type="text" v-model="form.checkdeposit_ref" class="form-control">
	            </div>

	            <div class="form-group">
	                <label>{{ $t('check.checkdeposit_status') }}</label>
	                <b-form-radio-group
	                    v-model="form.checkdeposit_status"
	                    value="1"
	                    checked
	                    :options="check_deposit_status_format"
	                >
	                </b-form-radio-group>
	            </div>

	            <div class="form-group">
	                <label>{{ $t('check.checkdeposit_date') }}</label>
	                <e-datepicker v-model="form.checkdeposit_date"></e-datepicker>
	            </div>
		    </form>
		    
		    <div class="col-8 m-auto">
		        <b-button block pill variant="primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
		    </div>
		</div>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Accounting from "@/mixins/Accounting.js"
	import Shutter from "@/mixins/Shutter.js"

    export default {
        name: "checkDepositForm",
        mixins: [Navigation, Payment, Accounting, Shutter],
        props: {
            paiements: Array,
            form: Object
        },
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitleAccoutingPlan: this.getTrad("compta.select_accounting_plan"),
                labelTitleConfiguration: this.getTrad("check.select_configuration"),
                check_deposit_status: this.getConfig('check_deposit_status'),
                check_deposit_status_format: [],
                check_deposit: null,
                
                table_busy: true,
                modal_loaded: false,
                processing: false,
                isLoading: false,
                isLoadingConfiguration: false,
                all_checks_loading: false,

                accounting_plans: [],
                filtred_payment_methods: [],
                configurations: [],
                errors: [],
                all_checks: [],
                payments_methods: [],
                payments_methods_ids: [],
                events_tab: {

				},
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.check_deposit_status_format = []

                for (let i = 0; i < this.check_deposit_status.length; i++) {
                    this.check_deposit_status_format.push({
                        text: this.getTrad(this.check_deposit_status[i].checkdepositstatus_label),
                        value: this.check_deposit_status[i].checkdepositstatus_id
                    })
                }
            },

            async checkForm()
            {
                if(!this.processing)
                {
                    this.processing = true

                    this.errors = []

                    if(this.errors.length == 0)
                    {
                        let params_to_send = {
                            "checkdeposit_accountingplan": this.form.checkdeposit_accountingplan.id,
                            "checkdeposit_ref": this.form.checkdeposit_ref,
                            "checkdeposit_status": this.form.checkdeposit_status,
                            "checkdeposit_date": this.form.checkdeposit_date,
                            "checkdeposit_configuration": this.form.checkdeposit_configuration.id,
                            "checks": this.paiements
                        }

                        let result = await this.addCheckDeposit(params_to_send);

                        if(result) {
                            this.successToast()
                            this.ok()
                        }
                    }

                    this.processing = false
                }
            }
        },
        computed: {
        	computed_checks: function(){
        	    if(this.errors && this.errors.indexOf('Check') > -1)
        	    {
        	        return false
        	    }

        	    return null
        	}
        },
        watch: {
            
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        }
    }
</script>
